import React, {useState, useEffect} from 'react'
import './about.css'

import sTwo from '../../../Images/Sammy/two.png'

const About = () => {

  const [phone, setPhone] = useState(window.innerWidth < 1025)
  useEffect(() => {window.scrollTo({top: 0, behavior: "smooth"})},[])


  return(
    <div id="about-container">
      <div id="about-title">About Us</div>
      <div id="about-mid-box">
        <div id="about-mid-left">
          <div id="about-mid-subtitle">
            ProTechXSolution. Has a well-earned reputation for repeatedly delivering exceptional projects of every size and scope. Providing services for all phases of a project, we specialize in Contracting, Security Services, and involved in the field of Marine, Oil, Gas, Petrochemical industries, Industrial Supplies . Industrial Services Design, General Contracting, Fire and Safety, IT and Telecom Tele Communication , Mechanical Electrical Instrumentation . Plumbing. HAVC, Building Materials, Tools, Travel and Tourism Food Trading and capable of executing turn-key jobs, which, alongside General Construction and our core business. Often tackling tight deadlines , budget constraints, and challenging site conditions, our professional staff continually improves management technology and financial systems to deliver. the most appropriate, project-specific solutions. 
            <br/><br/>
            ProTechXSolution is a Dynamic Experienced and Well Organized General Contract Firm dedicated to success. For 37 years, ProTechXSolutions has been Contracting and supplying Services, I and Security to a wide range of in the International level Objective that be recognized by our clients for our professional service and to keep them coming back year after year. We understand the needs of our clients: Advanced Technology. Quality Control Challenging Schedules. Skiled and Created Manpower Since our inception we added several Departments to our Company and have significantly expanded our firm. This year we professionally doubled our volume of Business ProTechXSolution has a proud Saudi heritage, dating back in 1983 and has been growing steadily since. We have progressively expanded over the years and now have branches in Middle East, Singapore, Malasiya, India and Unigreen Ltd, Tawkal Group, Trademate Solutions, Al Qahtani Group. The Group is capable of performing projects. Our success has been driven by our ability to respond quickly to changing market conditions that often produced many challenges but more importantly created new opportunities for long term growth. 
            <br/><br/>
            Importantly , we continue to make the strategic investments necessary to increase our competitiveness over the long term. we also continue to invest internally, building on our organizational capabilities by upgrading business development, human resources and safety and quality functions including the establishment of an exceptional internal audit department and ERP system - all with the objective of pursuing excellence. We have skilled team multi-disciplined professionals that are guided by the foresight and pioneering vision of the management team and armed with extensive working experience and dedication to the company's success. Looking ahead into our Fourth decade, we will undoubtedly continue to be tested by historical events unfolding across the region but am convinced that the steps we have taken over the last few years along with the commitment of all our employees and shareholders provides the necessary foundation for successfully meeting those challenges and harnessing exciting opportunities. We have a focused strategy to continue to expand our reinforcing and expanding our regional leadership in the gulf region. With the commitment of our team and the confidence of our clients, we look forward to achieving our ambitions.
          </div>
        </div>
        <div id="about-mid-right">
          <img src={sTwo} id="about-mid-right-img"/>  
        </div>
      </div>
    </div>
  )
}

export default About;












