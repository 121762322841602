import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import './products.css';

import pOne from '../../../Images/Sammy/one.png'
import pTwo from '../../../Images/Sammy/two.png'
import pThree from '../../../Images/Sammy/three.png'
import pFour from '../../../Images/Sammy/four.png'
import pFive from '../../../Images/Sammy/five.png'
import pSix from '../../../Images/Sammy/six.png'
import pSeven from '../../../Images/Sammy/seven.png'

import pData from './products.json'

const Products = () => {


  const obj = useLocation();
  const [phone, setPhone] = useState(window.innerWidth < 1025)
  const [objId, setObjId] = useState(obj.state.id)
  const [data, setData] = useState([])
  const [lists, setLists] = useState(false)
  const [subcontent, setSubcontent] = useState(false)
  const [image, setImage] = useState(pOne)

  useEffect(() => {
    setObjId(obj.state.id)
    pData.content.map(i => {
      if(i.id === objId) {
        setData(i)
        Object.keys(i).map(k => {
          if(k === "lists") {setLists(true)}
          else setLists(false)
          if(k === "subcontent") {setSubcontent(true)}
          else setSubcontent(false)
        })
      }
    })
    window.scrollTo({top: 0, behavior: "smooth"})
  },[objId,obj.state.id])

  var li = [pOne,pTwo,pThree,pFour,pFive,pSix,pSeven];

  return(
    <div id="pro-container">
      <div id="pro-title">{data.title}</div>
      <div id="pro-img-cont">
        <img id="pro-img" src={li[Math.floor(Math.random() * 6)]}/>
      </div>
      <div id="pro-subtitle">{data.subtitle}</div>
      {
        subcontent && 
        data.subcontent.map(i => {return(
          <div id="pro-subcontent-container">
            <div className="pro-subcontent-title">{i.title}</div>
            <div className="pro-subcontent-subtitle">{i.subtitle}</div>
          </div>
        )})
      }
      <div id="pro-lists-conts">
        {
          lists && 
          data.lists.map(i => {
            console.log(i.subtitle.length)
            if(i.subtitle.length > 9 && !phone) {
              return(
                <div className="pro-lists-container" id="pro-list-long">
                  <div className="pro-lists-title">{i.title}</div>
                  <div className="pro-lists-subtitle-container pro-list-cont-column">
                  {
                    i.subtitle.map(j => {
                      return <div className="pro-lists-subtitle pro-list-column"><div className="pro-lists-bullet">◾</div>{j}</div>
                    })
                  }
                  </div>
                </div>
              )
            }else {
              if(i.subtitle.length <= 3 && !phone) {
                return(
                  <div className="pro-lists-container" id="pro-lists-container-column">
                    <div className="pro-lists-title">{i.title}</div>
                    <div className="pro-lists-subtitle-container">
                    {
                      i.subtitle.map(j => {
                        return <div className="pro-lists-subtitle"><div className="pro-lists-bullet">◾</div>{j}</div>
                      })
                    }
                    </div>
                  </div>
                )
              }else {
                return(
                  <div className="pro-lists-container">
                    <div className="pro-lists-title">{i.title}</div>
                    <div className="pro-lists-subtitle-container">
                    {
                      i.subtitle.map(j => {
                        return <div className="pro-lists-subtitle"><div className="pro-lists-bullet">◾</div>{j}</div>
                      })
                    }
                    </div>
                  </div>
                )
              }
            }
          })
        }
      </div>
    </div>
  )
}

export default Products;