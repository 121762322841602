import React from 'react'
import Header from '../Header/header';
import { Outlet } from 'react-router';

import './main.css'
import Footer from '../Footer/footer';


const Main = () => {
  return(
    <div id="main-container">
      <Header />
      <div id="main-outlets">
      <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default Main;