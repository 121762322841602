import React,{useState, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import './home.css'
import './banner.css'

import dataFaq from './faq.json'

import arrowIcon from '../../../Images/Icons/arrow_back.svg';
// import about from '../../../Images/3D/man.png'
// import skills from '../../../Images/3D/sit.png'
import chevDown from '../../../Images/Icons/down.svg';
import star from '../../../Images/Icons/star.svg'
import pOne from '../../../Images/3D/p1.png'
import pTwo from '../../../Images/3D/p2.png'
import pThree from '../../../Images/3D/p3.png'
import pFour from '../../../Images/3D/p4.png'

import sTwo from '../../../Images/Sammy/two.png'
import sThree from '../../../Images/Sammy/three.png'
import sEleven from '../../../Images/Sammy/five.png'



//
import herOne  from '../../../Images/Banner/103.png'; 
import herTwo  from '../../../Images/Banner/105.jpg';
import herThree  from '../../../Images/Banner/102.jpg'; 
import herFour  from '../../../Images/Banner/106.jpg'; 

import gOne  from '../../../Images/Banner/103.png'; 
import gTwo  from '../../../Images/Banner/105.jpg';
import gThree  from '../../../Images/Banner/102.jpg'; 
import gFour  from '../../../Images/Banner/106.jpg'; 


const Home = () => {

  const nav = useNavigate()
  const [oneNum, setOneNum] = useState(1)
  const [homeTwo, setHomeTwo] = useState(false)
  const [homeThree, setHomeThree] = useState(false)
  const [homeFour, setHomeFour] = useState(false)
  const [homeFive, setHomeFive] = useState(false)
  const [homeSix, setHomeSix] = useState(false)
  const [homeSeven, setHomeSeven] = useState(false)
  const [homeEight, setHomeEight] = useState(false)
  const [homeTen, setHomeTen] = useState(false)
  const [phone, setPhone] = useState(window.innerWidth < 1025)

  const [eightDrop, setEightDrop] = useState(1000)
  
  useEffect(() => {
    if(oneNum === 1) {setTimeout(() => {setOneNum(2)}, 4000)}
    else if(oneNum === 2) {setTimeout(() => {setOneNum(3)}, 4000)}
    else if(oneNum === 3) {setTimeout(() => {setOneNum(4)}, 4000)}
    else if(oneNum === 4) {setTimeout(() => {setOneNum(5)}, 4000)}
    else if(oneNum === 5) {setTimeout(() => {setOneNum(6)}, 4000)}
    else {setTimeout(() => {setOneNum(1)}, 4000)}
  },[oneNum])

  const homeTwoObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeTwo(true)}})
  })
  const homeThreeObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeThree(true)}})
  })
  const homeFourObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeFour(true)}})
  })
  const homeFiveObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeFive(true)}})
  })
  // const homeSixObserve = new IntersectionObserver(e => {
  //   e.forEach(i => { if(i.isIntersecting) {setHomeSix(true)}})
  // })
  const homeSevenObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeSeven(true)}})
  })
  const homeEightObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeEight(true)}})
  })
  const homeTenObserve = new IntersectionObserver(e => {
    e.forEach(i => { if(i.isIntersecting) {setHomeTen(true)}})
  })
  

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"})
    homeTwoObserve.observe(document.querySelector(`#home-two-box`))
    homeThreeObserve.observe(document.querySelector(`#home-three-mid`))
    homeFourObserve.observe(document.querySelector(`#home-four-mid`))
    homeFiveObserve.observe(document.querySelector(`#home-five-box`))
    // homeSixObserve.observe(document.querySelector(`#home-six-box`))
    homeSevenObserve.observe(document.querySelector(`#home-seven-mid`))
    homeEightObserve.observe(document.querySelector(`#home-eight-mid`))
    homeTenObserve.observe(document.querySelector(`#home-ten-container`))
  },[])

  const [num, setNum] = useState(true)
  const [oneImg, setOneImg] = useState(herThree)
  const [twoImg, setTwoImg] = useState(herFour)

  useEffect(() => {
    if(!phone) {
      if(num) {
        setTimeout(() => {setNum(false)}, 5000);
        setOneImg(oneImg === herThree ? herOne : herThree);
      }
      else {
        setTimeout(() => {setNum(true)}, 5000);
        setTwoImg(twoImg === herFour ? herTwo : herFour);
      }
    }else {
      if(num) {
        setTimeout(() => {setNum(false)}, 5000);
        setOneImg(oneImg === gThree ? gOne : gThree);
      }
      else {
        setTimeout(() => {setNum(true)}, 5000);
        setTwoImg(twoImg === gFour ? gTwo : gFour);
      }
    }
  },[num])

  return(
    <div id="home-container">
      <div><Toaster position="top-center" reverseOrder={false}/></div>
      
      <div id="home-one-container">

        <div id="home-banner">
          <div 
            className="home-banner-img"
            style={{ backgroundImage: `url(${oneImg})` }}
            id={
              num === true ?  "home-banner-one-show" : "home-banner-one-hide"
            }/>
          <div 
            className="home-banner-img"
            style={{ backgroundImage: `url(${twoImg})` }}
            id={
              num === true ?  "home-banner-two-hide" : "home-banner-two-show"
            }/>
          <div id="home-banner-top">
            <div id="home-banner-top-box">

              {/* title */}
              {/* {(num && (oneImg === herOne || oneImg === gOne)) && <div className="home-banner-top-title">
                Setting new milestones in Patient Care
              </div>}
              {(!num && (twoImg === herTwo || twoImg === gTwo)) && <div className="home-banner-top-title">
                Gifting a chance to the little ones to make it big in life
              </div>}
              {(num && (oneImg === herThree || oneImg === gThree)) && <div className="home-banner-top-title">
                When every second counts, <br/> Count on us
              </div>}
              {(!num && (twoImg === herFour || twoImg === gFour)) && <div className="home-banner-top-title">
                Our commitment <br/> is to keep you safe
              </div>} */}

              <div className="home-banner-top-title">
                ProtechX Total IT Solutions
              </div>

              {/* subtitle */}
              {(num && (oneImg === herOne || oneImg === gOne)) &&  <div className="home-banner-top-subtitle">
                Best Enterprise Resource Planning Solutions providers in the industry
              </div>}
              {(!num && (twoImg === herTwo || twoImg === gTwo)) && <div className="home-banner-top-subtitle">
                Reputable clients and history of service
              </div>}
              {(num && (oneImg === herThree || oneImg === gThree)) && <div className="home-banner-top-subtitle">
                Life long maintenance for premium products
              </div>}
              {(!num && (twoImg === herFour || twoImg === gFour)) && <div className="home-banner-top-subtitle">
                We cover every aspect of your business's needs
              </div>}
            </div>
          </div>
        </div>

        {/* <div id="home-one-banner-cont">
          <div id="banner-one" className='banner-slide'></div>
          <div id="banner-two" className='banner-slide'></div>
          <div id="banner-three" className='banner-slide'></div>
        </div> */}

        <div id="home-one-left-box">
          {/* <div id="home-one-left-top">ProTechXSolutions</div> */}
          <div id="home-one-left-mid">
            <div id="home-mid-cursor"></div>
            {oneNum === 1 && <div className="home-one-left-mid-text">Pharmacy Management System.  </div>}
            {oneNum === 2 && <div className="home-one-left-mid-text">Hotel Management System. </div>}
            {oneNum === 3 && <div className="home-one-left-mid-text">Clinical Information System. </div>}
            {oneNum === 4 && <div className="home-one-left-mid-text">Pharmacy Management.</div>}
            {oneNum === 5 && <div className="home-one-left-mid-text">Service Management System. </div>}
            {oneNum === 6 && <div className="home-one-left-mid-text">ERP Implementation. </div>}
          </div>
          <div id="home-one-left-bottom">Making us one of the leading ERM service provider around the globe</div>
          <div id="home-one-left-bottom-btn-box">
            <div className="home-one-left-bottom-btn"><a href="tel:00966138971825">Contact Us</a></div>
            <div className="home-one-left-bottom-btn" onClick={() => {nav('../get-in-touch')}}><a>Request for a Quote</a></div>
          </div>
        </div>
        {/* <div id="home-one-anime-one"></div> */}
        {/* <div id="home-one-anime-two"></div> */}
      </div>
      
      <div id="home-two-container">
        <div id="home-two-top-box">Our Services</div>
        <div id="home-two-box">
        {homeTwo &&
          <div id="home-two-box-mid">
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">ERP Implementaion</div>
              <div className="home-two-box-indi-subtitle">
                When implemented with efficency, ERP enables companies to break down tradition organization silos.
              </div>
              <div className="home-two-box-indi-btn" onClick={() => {nav('../service/erp-implementation',{state: {id: 1}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Software Development</div>
              <div className="home-two-box-indi-subtitle">
                We are strongly focused on creating customer oriented software which is platform independent.
              </div>
              <div className="home-two-box-indi-btn" onClick={() => {nav('../service/software-development',{state: {id: 2}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Project Consultancy</div>
              <div className="home-two-box-indi-subtitle">
                To add value to our client business, we offer a comprehensive range of on and off site consulting services.
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/project-consultancy',{state: {id: 3}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Data capturing</div>
              <div className="home-two-box-indi-subtitle">
                We provide automated electronic data capture solution and services to logistics, retail, manufacturing and distribution companies.
              </div>
              <div className="home-two-box-indi-btn" onClick={() => {nav('../service/data-capturing',{state: {id: 4}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Security and Identification</div>
              <div className="home-two-box-indi-subtitle">
                ProTechXSolutions provide various services to the clients with respect to security and identification
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/security-identification',{state: {id: 5}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Mobile Application</div>
              <div className="home-two-box-indi-subtitle">
                ProTechXSolutions provide various services to the clients with respect to security and identification
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/mobile-application',{state: {id: 6}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Fire and Life Safety System</div>
              <div className="home-two-box-indi-subtitle">
                Fire and life safety systems ensure safety of employee and the overall welfare of a business.
              </div>
              <div className="home-two-box-indi-btn" onClick={() => {nav('../service/fire-life-saftey-system',{state: {id: 7}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Video Surveillance System</div>
              <div className="home-two-box-indi-subtitle">
                Video Information technology and engineering departments that keep current with the latest technology.
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/video-surveillance',{state: {id: 8}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">IT Solutions and Products</div>
              <div className="home-two-box-indi-subtitle">
                We help businesses become agile, flexible and highly efficient.
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/it-solutions-and-products',{state: {id: 9}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Queue Management System</div>
              <div className="home-two-box-indi-subtitle">
                A system which is used to control queuesof people form in locations and various situations in queue area.
              </div>
              <div className="home-two-box-indi-btn" onClick={() => {nav('../service/queue-management',{state: {id: 10}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">POS System and Cash Drawers</div>
              <div className="home-two-box-indi-subtitle">
                POS System enables business to replace standard cash registers with a stand-alone terminal.
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/pos-system-and-cash-drawers',{state: {id: 11}})}}>Read More</div>
            </div>
            <div className="home-two-box-indi">
              <div className="home-two-box-indi-icon"></div>
              <div className="home-two-box-indi-title">Banknote Counters and Data Collectors</div>
              <div className="home-two-box-indi-subtitle">
                We Provide variety of Banknotes Counters and Data Collectors equipped with value counting Functions. 
              </div>
              <div className="home-two-box-indi-btn"  onClick={() => {nav('../service/banknote-counters-and-data-collectors',{state: {id: 12}})}}>Read More</div>
            </div>
          </div>
        }
        </div>
      </div>
      
      {/* ABOUT US */}
      <div id="home-three-container">
        <div id="home-three-mid">
          {(homeThree && !phone) && <div id="home-three-mid-left">
            <img src={sTwo} id="home-three-mid-left-img"/>  
          </div>}
          {homeThree && <div id="home-three-mid-right">
            <div id="home-three-mid-title">About Us</div>
            {!phone &&
              <div id="home-three-mid-subtitle">
                ProTechXSolutions has a well-earned reputation for repeatedly delivering exceptional projects of every size and scope. Providing services for all phases of a project, we specialize in Contracting, Security Services, and involved in the field of Marine, Oil, Gas, Petrochemical industries, Industrial Supplies, Industrial Services, Design General contracting Contracting, Fire and Safety, IT and Telecom Tele Communication, Mechanical Electrical, Instrumentation, Plumbing, HAVC, Building Materials, Tools, Travel and Tourism, Food Trading and capable of executing turn-key jobs, which, alongside General Construction and our core business. Often tackling tight deadlines, budget constraints, and challenging site conditions, our professional staff continually improves management technology and financial systems to deliver the most appropriate, project-specific solutions.
              </div>
            }
            {
              phone &&
              <div id="home-three-mid-subtitle">
                ProTechXSolutions has a well-earned reputation for repeatedly delivering exceptional projects of every size and scope. Providing services for all phases of a project, we specialize in Contracting, Security Services, and involved in the field of Marine, Oil, Gas, Petrochemical industries, Industrial Supplies, Industrial Services, Design General contracting Contracting, Fire and Safety, IT and Telecom Tele Communication, Mechanical Electrical, Instrumentation, Plumbing, HAVC, Building Materials, Tools, Travel and Tourism, Food Trading and capable of executing turn-key jobs
              </div>
            }
            <div id="home-three-mid-btn" onClick={() => {nav("../about")}}>Read more</div>
          </div>}
        </div>
      </div>
      
      <div id="home-ten-container">
        <div className={homeTen ? "home-ten-indi home-ten-indi-anime" : "home-ten-indi"}>
          <div className="home-ten-icon-box">
            <div className="home-ten-icon"></div>
          </div>
          <div className="home-ten-title">Our Vision</div>
          <div className="home-ten-subtitle">
            We unceasingly make every effort to emerge as a destination where customers can confidently rely on each and every type of thier technological branding related requirements.
          </div>
        </div>
        <div className={homeTen ? "home-ten-indi home-ten-indi-anime" : "home-ten-indi"}>
          <div className="home-ten-icon-box">
            <div className="home-ten-icon"></div>
          </div>
          <div className="home-ten-title">Our Mission</div>
          <div className="home-ten-subtitle">
            Ultimately our enduring mission is to stabilize as the most sought out hub for all type of customers, regardless of thier type or size.
          </div>
        </div>
        <div className={homeTen ? "home-ten-indi home-ten-indi-anime" : "home-ten-indi"}>
          <div className="home-ten-icon-box">
            <div className="home-ten-icon"></div>
          </div>
          <div className="home-ten-title">Our Motto</div>
          <div className="home-ten-subtitle">
            Exceeding customer expectations has always been our motto.
          </div>
        </div>
      </div>

      {/* PRODUCTS */}
      <div id="home-nine-container">
        <div id="home-nine-title">Our Products</div>
        <div id="home-nine-box">
          <div id="home-nine-bottom">
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">ERP System</div>
              <div className="home-nine-bottom-indi-subtitle">ProTechXSolution System is about knowing what you have in your warehouse and where your stock is located</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/erp-system',{state: {id: 11}})}}>Read More</div>
            </div>
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">Food Soft</div>
              <div className="home-nine-bottom-indi-subtitle">FoodSoft is designed to suit every facet and need of the hotel industry enabling you to provide effective service.</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/food-soft',{state: {id: 12}})}}>Read More</div>
            </div>
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">Medi Kit</div>
              <div className="home-nine-bottom-indi-subtitle">Medicine Store module deals with Purchase of Items, Issue of Items, Maintenance of Stock, Automatic Reorder level Setting</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/medi-kit',{state: {id: 13}})}}>Read More</div>
            </div>
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">CIMS</div>
              <div className="home-nine-bottom-indi-subtitle">Our Lab management software automates and tracks your laboratory samples from login to invoicing and final reporting.</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/cims',{state: {id: 14}})}}>Read More</div>
            </div>
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">Medi Soft</div>
              <div className="home-nine-bottom-indi-subtitle">Medi Soft is a comprehensive Hospital Management Software (HMS). It caters to the requirements of hospitals</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/medi-soft',{state: {id: 15}})}}>Read More</div>
            </div>
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">eCRM</div>
              <div className="home-nine-bottom-indi-subtitle">Designed for business with multifaceted contact centre operations and high call volume, eCRM is a database and reporting application</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/ecrm',{state: {id: 16}})}}>Read More</div>
            </div>
            <div className="home-nine-bottom-indi">
              <div className="home-nine-bottom-indi-icon"></div>
              <div className="home-nine-bottom-indi-title">Customised Softwares</div>
              <div className="home-nine-bottom-indi-subtitle">We are providing Softwares for an individual or business that performs tasks to their needs.</div>
              <div className="home-nine-bottom-indi-btn" onClick={() => {nav('../products/customised-software',{state: {id: 17}})}}>Read More</div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="home-four-container">
        <div id="home-four-mid">
          {homeFour && <div id="home-four-mid-left">
            <div id="home-four-mid-title">Pay for quality driven services</div>
            <div id="home-four-mid-subtitle">
              Yor business can take advantage of our rich domain knowledge, highly skilled, trained and expreienced resources, use of latest and innovative technologies and our experience in developing highly cutomized, complex fully integrated solutions. We unceasingly make every effort to emerge as a destination where customers can confidently rely on for each and type of thier technological and branding related requirements.
            </div>
          </div>}
          {(homeFour && !phone) && <div id="home-four-mid-right">
            <img src={sThree} id="home-four-mid-right-img"/>  
          </div>}
        </div>
      </div>
      
      <div id="home-seven-container">
        <div id="home-seven-mid">
          {phone && <div id="home-seven-mid-title">Pay for qualified products</div>}
          {homeSeven && <div id="home-seven-mid-left">
            <div className="home-seven-mid-left-box">
              <div className="home-seven-mid-left-box-element">
                <div className="home-seven-mid-left-box-element-square">
                  <div className="home-seven-left-top">
                    <div className="home-seven-left-top-one">150+</div>
                    <div className="home-seven-left-top-two">Our Projects</div>
                  </div>
                  <div className="home-seven-left-bottom">They strengthen our reputation and sing the trust that other people carry.</div>
                </div>
                <div className="home-seven-mid-left-box-element-square">
                  <div className="home-seven-left-top">
                    <div className="home-seven-left-top-one">105+</div>
                    <div className="home-seven-left-top-two">Complete</div>
                  </div>
                  <div className="home-seven-left-bottom">Happy clients and projects have always been the key highlight at ProTechXSolutions</div>
                </div>
              </div>
              <div className="home-seven-mid-left-box-element">
                <div className="home-seven-mid-left-box-element-square">
                  <div className="home-seven-left-top">
                    <div className="home-seven-left-top-one">120+</div>
                    <div className="home-seven-left-top-two">Our Clients</div>
                  </div>
                  <div className="home-seven-left-bottom">We keep relationships with out client that lasts a life time</div>
                </div>
                <div className="home-seven-mid-left-box-element-square">
                  <div className="home-seven-left-top">
                    <div className="home-seven-left-top-one">55</div>
                    <div className="home-seven-left-top-two">Review</div>
                  </div>
                  <div className="home-seven-left-bottom">The testimonials we get speaks louder than the word of mouth that spreads</div>
                </div>
              </div>
            </div> 
          </div>}
          {homeSeven && <div id="home-seven-mid-right">
            {!phone && <div id="home-seven-mid-title">Pay for qualified products</div>}
            <div id="home-seven-mid-subtitle">
              ProTechXSolutions has a well-earned reputation for repeatedly delivering exceptional projects of every size and scope. Providing services for all phases of a project, we specialize in Contracting, Security Services, and involved in the field of Marine, Oil, Gas, Petrochemical industries, Industrial Supplies, Industrial Services, Design General contracting Contracting, Fire and Safety, IT and Telecom Tele Communication, Mechanical Electrical, Instrumentation, Plumbing, HAVC, Building Materials, Tools, Travel and Tourism, Food Trading and capable of executing turn-key jobs, which, alongside General Construction and our core business. Often tackling tight deadlines, budget constraints, and challenging site conditions, our professional staff continually improves management technology and financial systems to deliver the most appropriate, project-specific solutions.
            </div>
            <div id="home-seven-mid-btn" onClick={() => {nav("../get-in-touch")}}>Get in touch</div>
          </div>}
        </div>
      </div>
      
      <div id="home-eight-container">
        <div id="home-eight-mid">
          {phone && <div id="home-eight-mid-title">FAQ</div>}
          {(homeEight && phone) && <div id="home-eight-mid-right">
            <img src={sEleven} id="home-eight-mid-right-img"/>  
          </div>}
          {homeEight && <div id="home-eight-mid-left">
            {!phone && <div id="home-eight-mid-title">FAQ</div>}
            <div id="home-eight-mid-down">
              {
                dataFaq && dataFaq.content.map(i => 
                  <div className="home-eight-mid-box-cont" onClick={() => { eightDrop === i.id ? setEightDrop(1000) : setEightDrop(i.id) }}>
                    <div className="home-eight-mid-box-title">{i.title}</div>
                    <div className="home-eight-mid-drop-button">
                      <img
                        className={eightDrop === i.id ? "home-eight-mid-drop-img home-eight-rotate-down" : "home-eight-mid-drop-img home-eight-rotate-up"} 
                        src={chevDown} onClick={() => { eightDrop === i.id ? setEightDrop(1000) : setEightDrop(i.id) }}
                      />
                    </div>
                    { eightDrop === i.id && <div anime={eightDrop} className="home-eight-mid-content">{i.subtitle}</div>}  
                  </div>
                )
              }
            </div>
          </div>
          }
          {(homeEight && !phone) && <div id="home-eight-mid-right">
            <img src={sEleven} id="home-eight-mid-right-img"/>  
          </div>}
        </div>
      </div>
      
      <div id="home-five-container">
        <div id="home-five-top-box">What our clients say</div>
        <div id="home-five-box">
          {/* <img src={arrowIcon} className="home-five-btn"/> */}
          {homeFive &&
            <div id="home-five-box-mid">
              <div className="home-five-box-indi">
                <div className="home-five-box-indi-top">
                  <div className="home-five-box-indi-top-left">
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                  </div>
                  <div className="home-five-box-indi-top-right"><i>Excellent</i></div>
                </div>
                <div className="home-five-box-indi-mid">
                  I was pleased to find ProTechXSolution as an ERP solution that offers excellent value, as well as the specific functionality that our business requires. ProTechXSolution did a fantastic job of working together.
                </div>
                <div className="home-five-box-indi-bottom">
                  <div className="home-five-box-indi-bottom-left">
                    <img src={pOne} className="home-five-box-indi-bottom-left-icon" />
                  </div>
                  <div className="home-five-box-indi-bottom-right">
                    <div className="home-five-box-indi-bottom-right-title">Jeniffer Dennings</div>
                    <div className="home-five-box-indi-bottom-right-subtitle">CFO - Spektron</div>
                  </div>
                </div>
              </div>
              <div className="home-five-box-indi">
                <div className="home-five-box-indi-top">
                  <div className="home-five-box-indi-top-left">
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                  </div>
                  <div className="home-five-box-indi-top-right"><i>Outstanding</i></div>
                </div>
                <div className="home-five-box-indi-mid">
                  Our relationship with ProTechXSolution is now over five years, We would like to take this time to express our gratitude. They have always given us the opportunity to upgrade with new ideas and solutions. 
                </div>
                <div className="home-five-box-indi-bottom">
                  <div className="home-five-box-indi-bottom-left">
                    <img src={pTwo} className="home-five-box-indi-bottom-left-icon" />
                  </div>
                  <div className="home-five-box-indi-bottom-right">
                    <div className="home-five-box-indi-bottom-right-title">Christena Toris</div>
                    <div className="home-five-box-indi-bottom-right-subtitle">Finance Manager - Solae India</div>
                  </div>
                </div>
              </div>
              <div className="home-five-box-indi">
                <div className="home-five-box-indi-top">
                  <div className="home-five-box-indi-top-left">
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                  </div>
                  <div className="home-five-box-indi-top-right"><i>Excellent</i></div>
                </div>
                <div className="home-five-box-indi-mid">
                  We have been using the ProTechXSolution system for more than Eleven years now. With our considerable experience ProTechXSolution has proven to be the most adaptable, flexible, efficient, and productive of the lot.
                </div>
                <div className="home-five-box-indi-bottom">
                  <div className="home-five-box-indi-bottom-left">
                    <img src={pThree} className="home-five-box-indi-bottom-left-icon" />
                  </div>
                  <div className="home-five-box-indi-bottom-right">
                    <div className="home-five-box-indi-bottom-right-title">Andy Robertson</div>
                    <div className="home-five-box-indi-bottom-right-subtitle">IT Manager - Exacq</div>
                  </div>
                </div>
              </div>
              <div className="home-five-box-indi">
                <div className="home-five-box-indi-top">
                  <div className="home-five-box-indi-top-left">
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                  </div>
                  <div className="home-five-box-indi-top-right"><i>Excellent</i></div>
                </div>
                <div className="home-five-box-indi-mid">
                  We chose ProTechXSolution over several other ERP systems for its flexibility, scalability, and wide breadth of functionality. We liked the roadmap presented to us by ProTechXSolution Team as well as their support
                </div>
                <div className="home-five-box-indi-bottom">
                  <div className="home-five-box-indi-bottom-left">
                    <img src={pFour} className="home-five-box-indi-bottom-left-icon" />
                  </div>
                  <div className="home-five-box-indi-bottom-right">
                    <div className="home-five-box-indi-bottom-right-title">Chicana Males</div>
                    <div className="home-five-box-indi-bottom-right-subtitle">Vice President - Salto</div>
                  </div>
                </div>
              </div>
              <div className="home-five-box-indi">
                <div className="home-five-box-indi-top">
                  <div className="home-five-box-indi-top-left">
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                    <img src={star} className="home-five-box-indi-left-icon" />
                  </div>
                  <div className="home-five-box-indi-top-right"><i>Warming</i></div>
                </div>
                <div className="home-five-box-indi-mid">
                  We were very pleased with the multiple features, ProTechXSolution team made a difference in rapidly understanding our needs, identifying our business requirements and developing them in quick time.
                </div>
                <div className="home-five-box-indi-bottom">
                  <div className="home-five-box-indi-bottom-left">
                    <img src={pOne} className="home-five-box-indi-bottom-left-icon" />
                  </div>
                  <div className="home-five-box-indi-bottom-right">
                    <div className="home-five-box-indi-bottom-right-title">Hisham Prateek</div>
                    <div className="home-five-box-indi-bottom-right-subtitle">CFO - Soroof International</div>
                  </div>
                </div>
              </div>
          </div>
          }
          {/* <img src={arrowIcon} className="home-two-btn"/> */}
        </div>
      </div>
      
      {/* <div id="home-six-container">
        <div id="home-six-title">Associating Partners</div>
        <div id="home-six-box">
          {homeSix && <div id="home-six-box-one" className="home-six-box-one-anime">
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>    
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div>  
            <div className="home-six-box-elements"></div> 
          </div>}
          {homeSix && <div id="home-six-box-two" className="home-six-box-two-anime">
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>
            <div className="home-six-box-elements"></div>  
          </div>}
        </div>
        <div id="home-six-btn-box">
          <div id="home-six-btn" onClick={() => {nav('../clients')}}>View All</div>
        </div>
      </div> */}
    </div>
  )
}

export default Home;