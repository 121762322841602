import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './footer.css'
import styled from 'styled-components'

const Footer = () => {

  const nav = useNavigate();
  const date = new Date
  const [phone, setPhone] = useState(window.innerWidth < 1025 ? true : false)
  const [footerTop, setFooterTop] = useState(false)

  const observer = new IntersectionObserver(e => {
    e.forEach(i => {
      if(i.isIntersecting) { 
        if(phone) {
          let a = document.querySelector(`#footer-top-container-box`)
          a.setAttribute('class','footer-top-mount-p')
          console.log(a)
        }else {
          let a = document.querySelectorAll(`.footer-top-box`)
          a.forEach(i => {
              i.classList.add('footer-top-mount')
          })
        }
      }
    })
  })

  useEffect(() => {
    observer.observe(document.querySelector(`#footer-mid-container`))
  },[])

  return(
    <div id="footer-container">
      {/* <div id="footer-top-container">
        <div id="footer-top-container-box">
          <div className="footer-top-box" >
            <div className="footer-top-box-left">
              get
            </div>
          </div>
        </div>
      </div> */}

      <div id="footer-mid-container">
        <div className="footer-mid-box" id="footer-mid-one">
          <div className="footer-mid-title">CONTACT US</div>
          <div className="footer-mid-bottom">
            <div className="footer-mid-one-box">
              <div className="footer-mid-one-right">ProTechXSolutions, Al Khobar -31952, Kingdom of Saudi Arabia</div>
            </div>
            <div className="footer-mid-one-box">
              <div className="footer-mid-one-right"><a href="tel:00966138971825">+966 13 8971825</a></div>
            </div>
            <div className="footer-mid-one-box">
              <div className="footer-mid-one-right"><a href="mailto:info@protechxsolutions.com">info@protechxsolutions.com</a></div>
            </div>
          </div>
        </div>
        <div className="footer-mid-box">
          <div className="footer-mid-title">QUICK LINKS</div>
          <div className="footer-mid-bottom-two">
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {nav('home', {replace: true})}}>Home</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('about')}}>About us</div>
              {/* <div className="footer-mid-bottom-elements" onClick={() => {nav('clients')}}>Clients</div> */}
              <div className="footer-mid-bottom-elements" onClick={() => {nav('get-in-touch')}}>Get In Touch</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/data-capturing',{state: {id: 4}})}}>Data capturing</div>
            </div>
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/erp-implementation',{state: {id: 1}})}}>ERP Implementation</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/software-development',{state: {id: 2}})}}>Software Development</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/project-consultancy',{state: {id: 3}})}}>Project Consultancy</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/security-and-implementation',{state: {id: 5}})}}>Security and Identification</div>
            </div>
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/mobile-application',{state: {id: 6}})}}>Mobile Application</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/fire-and-life-safety-system',{state: {id: 7}})}}>Fire and Life Safety System</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/video-surveillance-system',{state: {id: 8}})}}>Video Surveillance System</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/it-solution-and-products',{state: {id: 9}})}}>IT Solutions and Products</div>
            </div>
            <div className="footer-mid-bottom-box">
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/queue-management-system',{state: {id: 10}})}}>Queue Management System</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/pos-system-and-cash-drawers',{state: {id: 11}})}}>POS System and Cash Drawers</div>
              <div className="footer-mid-bottom-elements" onClick={() => {nav('../service/banknote-counters-and-data-collectors',{state: {id: 12}})}}>Banknote Counters and Data Collectors</div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="footer-bottom-container">
        <div>© {date.getFullYear()} ProTechXSolutions. All rights reserved</div>
        {/* <div id="ansaf">Designed and Developed by <a href="https://chzapps.com/?country=IN">CHZAPPS INDIA</a></div> */}
      </div>
    </div>
  )
}

export default Footer;